import $ from 'jquery';

Drupal.behaviors.videoOnClick = {
  attach: function (context, settings) {

    //(function () { // REMOVE IF DRUPAL.

      'use strict';

      // Use context instead of document IF DRUPAL.
      var play_button = document.getElementById('play_button');
      var video_block = document.getElementById('video-block');
      var video_image_wrapper = document.getElementById('video-image-wrapper');

      // Mobile Menu Show/Hide.
      play_button.addEventListener('click', function (e) {
        video_block.classList.toggle('video-block-open');
        video_image_wrapper.classList.toggle('hide_video_image');
        $(this).parent().next().find("iframe")[0].src += "?autoplay=1";
      });


    //})(); // REMOVE IF DRUPAL.
//})(Drupal); // UNCOMMENT IF DRUPAL.
  }
};